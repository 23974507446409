<template>
  <div class="bb-onboarding">
    <div class="bg-cercles bb-onboarding__container">
      <div class="bb-onboarding__wrapper">
        <div class="bb-onboarding__header">
          <div class="bb-onboarding__step">{{ $t("onboarding.configuring_your_list") }}: {{ currentStepNumber }}/{{ totalSteps }}</div>

          <div class="bb-onboarding__survey">
            <div class="bb-survey">
              <div class="bb-survey__logo">
                <img src="../../assets/img/ico/ico-cover-favicon-success.svg" alt="" />
              </div>
              <div class="bb-onboarding__title">{{ $t("onboarding.howDoYouWantToBegin") }}</div>

              <div class="bb-survey__items">
                <div class="bb-survey__item">
                  <div class="bb-form-checkbox">
                    <input type="radio" name="checkbox" id="emptyList" value="emptyList" @click="enableButton" />
                    <label for="emptyList" class="bb-form-checkbox__label">
                      {{ $t("onboarding.emptyListTitle") }}: {{ $t("onboarding.emptyListDescription") }}
                    </label>
                  </div>
                </div>
                <div class="bb-survey__item">
                  <div class="bb-form-checkbox">
                    <input type="radio" name="checkbox" id="listWithIdeas" value="listWithIdeas" @click="enableButton" />
                    <label for="listWithIdeas" class="bb-form-checkbox__label">
                      {{ $t("onboarding.listWithIdeasTitle") }}: {{ $t("onboarding.listWithIdeasDescription") }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bb-onboarding__content">
          <div class="bb-onboarding__actions">
            <LoadingButton v-if="saving" />

            <!-- Button has to be disabled is there is no check selected. -->
            <button
              v-else
              :disabled="conditionToDisable"
              @click.prevent="next"
              class="button button--primary button--block"
            >
              {{ $t("generic.next") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

import Steps from "./mixins/Steps";
import LoadingButton from "../../components/LoadingButton";

export default {
  name: "OnboardingStepListTemplate",
  components: {
    LoadingButton,
  },
  mixins: [
    Steps,
  ],
  data() {
    return {
      saving: false,
      conditionToDisable: true,
      selectedOption: null,
    };
  },
  methods: {
    async next() {
      this.saving = true;

      try {
        let success = true;

        if (this.selectedOption === "listWithIdeas") {
          success = await this.setListTemplate();
        }

        if (success) {
          mixpanel.track("Onboarding llista precarregada", {
            "Resposta": document.querySelector(
              "input[name=\"checkbox\"]:checked + label"
            ).innerText,
          });
        }

        return this.goToNextStep();
      } finally {
        this.saving = false;
      }
    },
    enableButton(event) {
      this.conditionToDisable = false;
      this.selectedOption = event.target.value;
    },
    async setListTemplate() {
      try {
        const response = await this.$store.dispatch("setListTemplate", {});

        return response.status === 200;
      } catch (error) {
        console.error('Error updating feature flag:', error);
        return false;
      }
    }
  }
};
</script>
