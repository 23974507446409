import { mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters({
            countryCode: "countryCode",
        }),

        //=====================================================================
        // Steps are defined here.
        //=====================================================================
        allStepRoutes() {
            return this.createSteps((addStep) => {
                // NOTE: `addStep` receives route names, not component names.

                addStep("onboarding1");
                addStep("onboardingColaborador", { skip: 1 });
                addStep("OnboardingStepInvitacionEnviada", { count: false});

                if (this.countryCode === "ES") {
                    addStep("OnboardingStepHowDidYouFindUs");
                }
                if (this.countryCode === "MX") {
                    addStep("OnboardingStepWhyDidYouRegister");
                }

                //addStep("onboardingSurvey");

                if (this.countryCode === "ES") {
                    addStep("OnboardingStepListTemplate");
                }

                addStep("onboarding2");
            });
        },

        countrySpecificStep() {
            switch (this.countryCode) {
            case "ES":
                return "OnboardingStepHowDidYouFindUs";
            case "MX":
                return "OnboardingStepWhyDidYouRegister";
            default:
                return false;
            }
        },
        currentStepInfo() {
            const currentStepRouteName = this.$route.name;
            const allStepRoutes = this.allStepRoutes;
            const currentStep = allStepRoutes.find(step => step.name === currentStepRouteName);

            return currentStep;
        },
        currentStepNumber() {
            return this.currentStepInfo?.stepNumber ?? "?";
        },
        totalSteps() {
            const last = this.allStepRoutes.findLast((step) => step.count);

            return last.stepNumber;
        },
        nextStepRouteName() {
            const currentStepInfo = this.currentStepInfo;
            const i = currentStepInfo.index;

            return this.allStepRoutes[i + 1]?.name;
        },
        skipStepRouteName() {
            const currentStepInfo = this.currentStepInfo;

            const skip = currentStepInfo.skip;
            if (!skip) {
                return this.nextStepRouteName;
            }

            const i = currentStepInfo.index;

            return this.allStepRoutes[i + 1 + skip]?.name;
        },
    },
    methods: {
        createSteps(callback) {
            const steps = [];
            let nextStepNumber = 1;

            const DEFAULT_OPTIONS = {
                skip: 0,
                count: true,
            };

            const addStep = (name, options = {}) => {
                const stepInfo = {
                    ...DEFAULT_OPTIONS,
                    ...options,

                    stepNumber: -1,
                    index: steps.length,
                    name,
                };
                if (stepInfo.count) {
                    stepInfo.stepNumber = nextStepNumber++;
                }

                steps.push(stepInfo);
            }

            callback(addStep);

            return steps;
        },
        goToNextStep() {
            return this.$router.push({ name: this.nextStepRouteName });
        },
        skipStep() {
            return this.$router.push({ name: this.skipStepRouteName });
        },
    },
}
